import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import CategoryCard from '../../components/molecules/categoryCard'
import Layout from '../../components/organisms/layout'
import BreakPoints from '../../styles/breakPoints'
import { CategoryKind } from '../../types/CategoryKind'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  .category-card:nth-of-type(2) {
    height: 343px;
  }
  ${BreakPoints.medium} {
    .category-card {
      height: auto;
    }
  }
`

type IProps = PageProps<GatsbyTypes.ManagePageQuery>

/**
 * 管理職機能ページ
 * @constructor
 */
const ManagePage = ({ data, location }: IProps) => {
  // GraphQLで取得したデータを保持
  const items = data.awsGraphQL.listHelpCategories?.items || []

  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: '管理職機能' },
  ]
  return (
    <Layout
      location={location}
      paths={paths}
      categoryKind={CategoryKind.MANAGE}
    >
      <SEO title="管理職機能" />
      <CommonH2 label="管理職機能" />
      <p className="txt-center mt-020 mb-020">
        承認方法や休暇管理等、主に管理職のかたが利用する機能についてご案内します。
      </p>
      <Wrapper>
        {[...items]
          .sort((a, b) => (a?.sortNo || 0) - (b?.sortNo || 0))
          .filter((item) => !item?._deleted)
          .map((item) => {
            const helpCategoryId = item?.helpCategoryId || ''
            const slug = item?.slug || ''
            const title = item?.title || ''
            return (
              <CategoryCard
                key={`category-card-${helpCategoryId}`}
                helpCategoryId={helpCategoryId}
                categoryTitle={title}
                slug={slug}
                limit={10}
                visibleAllLink
              />
            )
          })}
      </Wrapper>
    </Layout>
  )
}

export default ManagePage

export const pageQuery = graphql`
  query ManagePage {
    awsGraphQL {
      listHelpCategories(filter: { categoryKind: { eq: 3 } }) {
        items {
          helpCategoryId
          sortNo
          title
          slug
          categoryKind
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
